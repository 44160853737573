<template>
    <div class="blog">
        <v-toolbar class="toolbar" dense flat>
            <v-toolbar-title> Nos Productions </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items id="descendent-checkbox">
                <v-checkbox
                    v-model="filters.descendent"
                    label="Plus ancien au plus récent "
                ></v-checkbox>
            </v-toolbar-items>
            <v-toolbar-items>
                <v-select
                    v-model="filters.categories"
                    class="category-select"
                    :items="categories"
                    chips
                    attach
                    clearable
                    label="Catégories"
                    multiple
                    persistent-hint
                    deletable-chips
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                        </v-chip>
                        <v-chip v-if="index === 1">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span
                            v-if="index === 2"
                            class="grey--text text-caption"
                        >
                            (+{{ filters.genres.length - 1 }} autre{{
                                filters.genres.length > 1 ? 's' : ''
                            }})
                        </span>
                    </template>
                </v-select>
            </v-toolbar-items>
            <v-toolbar-items>
                <v-select
                    v-model="filters.genres"
                    class="production-select"
                    :items="genres"
                    chips
                    attach
                    clearable
                    multiple
                    label="Genres"
                    persistent-hint
                    deletable-chips
                >
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                            <span>{{ item }}</span>
                        </v-chip>
                        <v-chip v-if="index === 1">
                            <span>{{ item }}</span>
                        </v-chip>
                        <span
                            v-if="index === 2"
                            class="grey--text text-caption"
                        >
                            (+{{ filters.genres.length - 1 }} autre{{
                                filters.genres.length > 1 ? 's' : ''
                            }})
                        </span>
                    </template>
                </v-select>
            </v-toolbar-items>
            <v-toolbar-items class="d-flex">
                <v-text-field
                    v-model="filters.search"
                    class="d-flex search-field"
                    label="Rechercher"
                    placeholder="Titre ou auteur"
                    filled
                    rounded
                    dense
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    single-line
                ></v-text-field> </v-toolbar-items
        ></v-toolbar>
        <div v-if="loading" id="loading">
            <pulse-loader :color="color"></pulse-loader>
        </div>
        <v-container v-else fluid class="container-padding">
            <v-row>
                <v-col v-for="(item, index) in filteredPosts" :key="index">
                    <blog-card
                        :episode-id="item.id"
                        :title="item.title"
                        :text="item.text"
                        :authors="item.authors"
                        :categories="item.categories"
                        :genres="item.genres"
                        :img="item.img"
                        :to="`blogcontent/${item.id}`"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import BlogCard from '../components/productions/BlogCard.vue';
import {
    getIllustrationUrlFromEpisode,
    getAuthorDisplayName,
    getAuthorNameById,
    sortByPublishedDate,
    retrieveText,
} from '../utils';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    name: 'Productions',
    components: {
        BlogCard,
        PulseLoader,
    },
    data() {
        // const posts = require("../mock/blog_data");
        return {
            loading: true,
            color: 'gray',
            size: '100',
            filters: {
                search: '',
                genres: [],
                categories: [],
                descendent: false,
            },
            updateRouteSource: '',
            categories: [],
            genres: [],
            posts: [],
            filteredPosts: [],
            episodes: [],
            defaultImage: 'https://cdn.vuetifyjs.com/images/cards/mountain.jpg',
        };
    },
    watch: {
        '$route.query': function (to, from) {
            if (this.updateRouteSource === '') {
                this.updateRouteSource = 'fromHistory';
                this.filters = {
                    ...this.filters,
                    search: to.search ? to.search : '',
                    genres: to.genres ? to.genres : [],
                    categories: to.categories ? to.categories : [],
                };
            } else {
                this.updateRouteSource = '';
            }
        },
        filters: {
            deep: true,
            handler: function (val) {
                this.filterEpisodes(val.search, val.genres, val.categories);
                if (this.updateRouteSource === '') {
                    this.updateRouteSource = 'fromfilters';
                    this.updateRoute(val);
                } else {
                    this.updateRouteSource = '';
                }
            },
        },
    },
    created: async function () {
        let resp = await axios.get(
            process.env.VUE_APP_API_URL + '/episodes-all'
        );
        this.episodes = resp.data;

        resp = await axios.get(process.env.VUE_APP_API_URL + '/genres');
        this.genres = resp.data.map((data) => data.name);

        resp = await axios.get(process.env.VUE_APP_API_URL + '/categories');
        this.categories = resp.data.map((data) => data.name);

        this.initalizePosts();

        const { search, genres, categories } = this.$route.query;

        if (search) {
            this.filters.search = decodeURI(search);
        }
        if (genres) {
            this.filters.genres = genres.split(',').map(decodeURI);
        }

        if (categories) {
            this.filters.categories = categories.split(',').map(decodeURI);
        }

        this.loading = false;
    },
    methods: {
        getAuthorDisplayName: getAuthorDisplayName,
        getAuthorNameById: getAuthorNameById,
        async getEpisodeCategoriesAndGenresByOpusId(id) {
            const resp = await axios.get(
                process.env.VUE_APP_API_URL + `/opuses/${id}`
            );
            const opus = resp.data;
            return {
                categories: opus.categories.map((x) => x.name),
                genres: opus.genres.map((x) => x.name),
            };
        },
        getIllustrationUrlFromEpisode: getIllustrationUrlFromEpisode,
        sortByPublishedDate: sortByPublishedDate,
        filterEpisodes(search, genres, categories) {
            this.filteredPosts = this.posts;

            const lowerSearch = search.toLowerCase().trim();

            if (search) {
                this.filteredPosts = this.filteredPosts.filter(
                    (episode) =>
                        episode.title.toLowerCase().includes(lowerSearch) ||
                        episode.authors.some((author) =>
                            author.nickname.toLowerCase().includes(lowerSearch)
                        ) ||
                        episode.opusTitle.toLowerCase().includes(lowerSearch)
                );
            }

            if (genres && genres.length) {
                this.filteredPosts = this.filteredPosts.filter((episode) => {
                    return genres.every((genre) =>
                        episode.genres.includes(genre)
                    );
                });
            }

            if (categories && categories.length) {
                this.filteredPosts = this.filteredPosts.filter((episode) => {
                    return episode.categories.every((category) =>
                        categories.includes(category)
                    );
                });
            }

            this.filteredPosts = this.filteredPosts.sort(
                this.sortByPublishedDate(this.filters.descendent)
            );
        },
        initalizePosts() {
            this.posts = [];
            this.episodes.forEach((episode) => {
                this.updatePosts(episode);
            });
            this.filteredPosts = this.posts.sort(
                this.sortByPublishedDate(this.filters.descendent)
            );
        },
        updatePosts(episode) {
            const { categories, genres, authors, title } = episode.opus;
            if (authors && episode.published_at !== null) {
                this.posts.push({
                    id: episode.id,
                    authors,
                    title: episode.title,
                    img: this.getIllustrationUrlFromEpisode(episode),
                    text: this.retrieveText(episode.content),
                    opusTitle: title,
                    categories: categories.map((categorie) => categorie.name),
                    genres: genres.map((genre) => genre.name),
                    published_at: episode.published_at,
                });
            }
        },
        updateRoute(val) {
            this.$router
                .push({
                    query: {
                        ...this.$route.query,
                        search: encodeURI(val.search.trim()) || undefined,
                    },
                })
                .catch((error) => {
                    if (error.name !== 'NavigationDuplicated') {
                        throw error;
                    }
                });
            this.$router
                .push({
                    query: {
                        ...this.$route.query,
                        genres:
                            val.genres && val.genres.length
                                ? val.genres.map(encodeURIComponent).join(',')
                                : undefined,
                    },
                })
                .catch((error) => {
                    if (error.name !== 'NavigationDuplicated') {
                        throw error;
                    }
                });

            this.$router
                .push({
                    query: {
                        ...this.$route.query,
                        categories:
                            val.categories && val.categories.length
                                ? val.categories
                                      .map(encodeURIComponent)
                                      .join(',')
                                : undefined,
                    },
                })
                .catch((error) => {
                    if (error.name !== 'NavigationDuplicated') {
                        throw error;
                    }
                });
        },
        retrieveText: retrieveText,
    },
};
</script>

<style lang="scss">
.container-padding {
    padding-left: 10%;
    padding-right: 5%;
}

.toolbar {
    background-color: inherit !important;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

.v-toolbar__items {
    margin-left: 1em;
}

.search-field {
    margin: auto 0;
}

.production-select {
    max-width: 24rem;
}

.category-select {
    max-width: 24rem;
}

#loading {
    margin-top: 15%;
}

#descendent-checkbox {
    padding-top: 0.5%;
}
</style>
