<template>
    <v-card link class="pa-20 blog-card" :to="to">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="headline">{{
                    title
                }}</v-list-item-title>
                <v-list-item-subtitle
                    >by {{ authorsName }}</v-list-item-subtitle
                >
            </v-list-item-content>
        </v-list-item>

        <v-img :src="img" height="194"></v-img>

        <v-card-text> {{ text | truncate(150) }} </v-card-text>
        <v-card-actions data-app>
            <v-container fluid>
                <v-row class="align-content: center;" no-gutters>
                    <v-col class="mb-6">
                        <v-chip-group>
                            <v-chip
                                v-for="(item, index) in categories"
                                :key="index"
                                color="#FF8A80"
                                link
                                >{{ item }}</v-chip
                            >
                        </v-chip-group>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="mb-6">
                        <v-chip-group>
                            <v-chip
                                v-for="(item, index) in genres"
                                :key="index"
                                color="#CE93D8"
                                link
                            >
                                {{ item }}
                            </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: 'Blogcard',
    props: {
        episodeId: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        authors: {
            type: Array,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        genres: {
            type: Array,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
    },
    computed: {
        authorsName() {
            return this.authors.map((author) => author.nickname).join(', ');
        },
    },
};
</script>
<style>
.blog-card {
    max-height: 550px;
    width: 485px;
}
</style>
